import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, filter, shareReplay, tap} from 'rxjs/operators';
import {FacilityObjectService} from '../services/facility-object.service';
import {ActivatedRoute} from '@angular/router';
import {SelectView} from '../models/SelectView';
import {merge, Observable, Subject, Subscription} from 'rxjs';
import {SensorService} from '../services/sensor.service';
import {PowerAlarmDefinition} from '../models/PowerAlarmDefinition';
import {PowerAlarmService} from '../services/power-alarm.service';
import {User} from '../models/User';

@Component({
  selector: 'app-add-tangensfi-definition',
  templateUrl: './add-tangensfi-definition.component.html',
  styleUrls: ['./add-tangensfi-definition.component.css']
})
export class AddTangensfiDefinitionComponent implements OnInit, OnDestroy {
  form: FormGroup;
  objects$: Observable<SelectView[]>;
  activeSensors$: Observable<any[]>;
  reactiveSensors$: Observable<any[]>;
  powerAlarmDefinitions$: Observable<PowerAlarmDefinition[]>;
  reloadWithObjectId$: Subject<number> = new Subject<number>();
  subscriptions: Subscription[] = [];
  signs = ['<', '<=', '=', '>', '>='];
  success = false;
  failure = false;

  constructor(private facilityObjectService: FacilityObjectService,
              private fb: FormBuilder,
              private powerAlarmService: PowerAlarmService,
              private sensorService: SensorService) { }

  ngOnInit() {
    this.objects$ = this.facilityObjectService.findAllSelectViews().pipe(
      shareReplay(1)
    );

    this.form = this.fb.group({
      objectId: [null, Validators.required],
      activeSensorId: [null, Validators.required],
      reactiveSensorId: [null, Validators.required],
      referenceValue: [null, Validators.required],
      sign: [null, Validators.required]
    });

    const sub = merge(
        this.form.get('objectId').valueChanges,
        this.reloadWithObjectId$
      ).pipe(
      tap(_ => this.form.get('activeSensorId').patchValue(null)),
      tap(_ => this.form.get('reactiveSensorId').patchValue(null)),
      filter(id => !!id),
    ).subscribe(objectId => {
      this.activeSensors$ = this.sensorService.findActiveForObject(objectId).pipe(
        shareReplay(1)
      );
      this.reactiveSensors$ = this.sensorService.findReactiveForObject(objectId).pipe(
        shareReplay(1)
      );

      this.powerAlarmDefinitions$ = this.powerAlarmService.findAllForObject(objectId).pipe(
        shareReplay(1)
      );
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  refreshPowerAlarms() {
    console.log('odświeżam');
    this.powerAlarmDefinitions$ = this.powerAlarmService.findAllForObject(this.form.get('objectId').value).pipe(
      shareReplay(1)
    );
  }

  delete(alarmId: string) {
    if(window.confirm('Czy chcesz usunąć definicję alarmu?')){
      this.powerAlarmService.delete(alarmId).subscribe(
        response => {
          this.refreshPowerAlarms();
        }, error => {
          console.log('ERROR');
          console.log(error);
        }
      );
    }
  }

  onSubmit() {
    this.failure = false;
    this.success = false;
    if (this.form.valid) {
      this.powerAlarmService.save(this.form.value).subscribe(
        (result) => {this.success = true; this.reloadWithObjectId$.next(this.form.get('objectId').value);},
        (error) => {this.success = false; console.log(error);}
      );
    } else {
      this.failure = true;
    }
  }
}
