import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EstInputComponent} from './est-input/est-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EstSelectComponent} from './est-select/est-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [EstInputComponent, EstSelectComponent],
  exports: [EstInputComponent, EstSelectComponent]
})
export class ControlsModule {
}
