import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appPatternOnly]'
})

export class PatternOnlyDirective {

  @Input() appPatternOnly: string;

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const pattern = new RegExp(this.appPatternOnly);
    const e = <KeyboardEvent>event;
    if (this.appPatternOnly.length > 0) {
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }

      if (pattern.test(String.fromCharCode(e.keyCode)) || pattern.test(e.key)) {
        return;
      }

      e.preventDefault();
    }
  }
}
