import {Component, OnInit} from '@angular/core';
import {DeviceService} from '../services/device.service';
import {ActivatedRoute} from '@angular/router';
import {DataLogger} from '../models/DataLogger';

@Component({
  selector: 'app-show-device',
  templateUrl: './show-device.component.html',
  styleUrls: ['./show-device.component.css']
})
export class ShowDeviceComponent implements OnInit {

  device: DataLogger;

  constructor(private deviceService: DeviceService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    // this.deviceService.getDevice(this.route.snapshot.paramMap.get('id'))
    //   .subscribe((device: DataLogger) => {
    //     this.device = device;
    //   });
  }

}
