import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {Notification} from '../models/Notification';
import {Enumerations} from '../models/enums';
import {flatMap} from 'rxjs/operators';
import {UtilityService} from '../services/utility.service';

@Component({
  selector: 'app-show-notification',
  templateUrl: './show-notification.component.html',
  styleUrls: ['./show-notification.component.css']
})
export class ShowNotificationComponent implements OnInit {
  notification: Notification = null;

  constructor(private actvatedRoute: ActivatedRoute,
              private router: Router,
              public util: UtilityService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.notificationService.getNotification(+this.actvatedRoute.snapshot.paramMap.get('id'))
      .subscribe((res: Notification) => this.notification = res);
  }


  editNotification(id: number) {
    return this.router.navigate(['/edit/notification/' + id]);
  }
}
