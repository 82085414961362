import {Component, OnInit} from '@angular/core';
import * as ct from 'countries-and-timezones';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DeviceService} from '../services/device.service';
import {UtilityService} from '../services/utility.service';
import {DeviceMeters} from '../models/DeviceMeters';
import {MeterLocation} from '../models/MeterLocation';
import {MeterModel} from '../models/MeterModel';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {Client} from '../models/Client';

@Component({
  selector: 'app-new-object',
  templateUrl: './new-object.component.html',
  styleUrls: ['./new-object.component.css']
})
export class NewObjectComponent implements OnInit {

  selectedCountryTimezones: ct.Timezone[] = [];
  objectForm: FormGroup;
  devices: DeviceMeters[] = [];
  locations: MeterLocation[] = [];
  selectedDevice: DeviceMeters;
  meterModels: MeterModel[] = [];
  clients: Client[] = [];
  devicesLoaded = false;

  constructor(private fb: FormBuilder, private deviceService: DeviceService, private utilityService: UtilityService,
              private router: Router, public authService: AuthService) {
  }

  ngOnInit() {
    this.deviceService.getDevicesWithoutObject().subscribe(devices => {
      this.devices = devices;
      this.devicesLoaded = true;
    });
    this.deviceService.getMeterModels().subscribe(meterModels => this.meterModels = meterModels);
    this.authService.getClients().subscribe(clients => this.clients = clients);

    this.objectForm = this.fb.group({
      deviceId: [null, Validators.required],
      serialNumber: ['', []],
      city: ['', Validators.required],
      street: ['', Validators.required],
      province: ['', Validators.required],
      postal: ['', Validators.required],
      country: ['', Validators.required],
      timezone: [{value: '', disabled: true}, Validators.required],
      phone: ['', [Validators.pattern(/^\+?[0-9 ]+$/)]],
      slave: ['1', [Validators.min(1), Validators.max(255)]],
      locations: this.fb.array([]),
      meterModels: this.fb.array([]),
      clientId: [this.authService.user.roles.indexOf('ADMIN') < 0 ? this.authService.user.clientId : '', Validators.required],
      mall: ['', ],
      area: ['', ],
      storeNumber: ['', Validators.required],
    });

    this.deviceService.getLocations().subscribe(l => this.locations = l);
  }

  get f() {
    return this.objectForm.controls;
  }

  updateTimezone() {
    console.log(this.f.country.value);
    this.selectedCountryTimezones = ct.getTimezonesForCountry(this.f.country.value);
    this.f.timezone.enable();
    this.f.timezone.setValue(this.selectedCountryTimezones[0].name);
  }

  selectDevice(value: number) {
    this.selectedDevice = this.devices.find(d => d.id.toString() === value.toString());

    console.log(this.objectForm.get('deviceId'));

    while ((<FormArray>this.f.locations).length > 0) {
      (<FormArray>this.f.locations).removeAt(0);
      (<FormArray>this.f.meterModels).removeAt(0);
    }

    for (const meter of this.selectedDevice.meters) {
      (<FormArray>this.f.locations).push(new FormControl('', [Validators.required]));
      (<FormArray>this.f.meterModels).push(new FormControl('', [Validators.required]));
    }
  }

  onSubmit() {
    this.utilityService.touchAllFormControls(this.objectForm);
    if (this.objectForm.valid) {
      this.deviceService.addNewObject(this.objectForm.value).subscribe(
        response => {
          this.router.navigate(['devices/' + this.selectedDevice.identifier]);
        }
      );
      console.log(this.objectForm.value);
    } else {
      console.log('błąd');
      console.log(this.objectForm);
    }
  }

}
