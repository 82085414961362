import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  public findSelectViewsForObject(objectId: number): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/sensors/for/object/' + objectId);
  }

  findActiveForObject(objectId: number) {
    return this.http.get<any[]>(this.apiUrl + '/active/sensors/for/object/' + objectId);
  }

  findReactiveForObject(objectId: number) {
    return this.http.get<any[]>(this.apiUrl + '/reactive/sensors/for/object/' + objectId);
  }
}
