import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-est-select',
  templateUrl: './est-select.component.html',
  styleUrls: ['./est-select.component.css']
})
/**
 * Własna implementacja selecta, żeby ładnie wyglądało
 */
export class EstSelectComponent implements OnInit, OnChanges {

  @Input() controlName: string;
  @Input() controlIndex: number = null;
  @Input() form: FormGroup;
  @Input() placeholder: string;
  @Input() minimalStyle = false;
  @Input() controlDisabled = false;
  @Input() searchbar = false;
  @Input() items = [];
  control: AbstractControl;

  constructor() {
  }

  ngOnInit() {
    if (this.controlIndex !== null) {
      console.log('inicjuję z form array');
      this.control = (<FormArray>this.form.controls[this.controlName]).at(this.controlIndex);
    } else {
      this.control = this.form.controls[this.controlName];
    }

    if (this.controlDisabled) {
      this.control.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control) {
      if (!this.controlDisabled) {
        this.control.enable();
      } else {
        this.control.disable();
      }
    }
  }
}
