import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {User} from '../../models/User';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: User[];

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.refreshUsers();
  }

  removeUser(username: string) {

    this.authService.removeUser(username).subscribe(
      response => {
        this.refreshUsers();
      }, error => {
        console.log(error);
      }
    );
  }

  refreshUsers() {
    console.log('odświeżam');
    this.authService.getUsers().subscribe(
      (users: User[]) => {
        this.users = users;
      });
  }

  editUser(username: string){
    this.router.navigate(['/admin/edit/user/', username]).then();
  }
}
