import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-est-input',
  templateUrl: './est-input.component.html',
  styleUrls: ['./est-input.component.css']
})
/**
 * Własna implementacja textboxa, żeby ładnie wyglądało
 */
export class EstInputComponent implements OnInit {

  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input() placeholder: string;

  @Input() type = 'text';
  @Input() defaultErrors = true;

  @Input() isRequired = true;

  control: AbstractControl;

  constructor() {
  }

  ngOnInit() {
    this.control = this.form.controls[this.controlName];
  }

}
