import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {SelectView} from '../models/SelectView';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityObjectService {
  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  public findAllSelectViews() {
    return this.http.get(this.apiUrl + '/get/objects').pipe(
      map((result: any[]) => {
        return result.map(object => new SelectView(object.id,
          `${object.storeNumber} - ${object.mall} - ${object.city}, ${object.country}`
        ));
      })
    );
  }
}
