import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectView} from '../models/SelectView';
import {merge, Observable, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, flatMap, shareReplay, tap} from 'rxjs/operators';
import {FacilityObjectService} from '../services/facility-object.service';
import {SensorService} from '../services/sensor.service';
import {ActivatedRoute} from '@angular/router';
import {AlarmDefinitionService} from '../services/alarm-definition.service';
import {AlarmDefinition} from '../models/AlarmDefinition';
import {HolidayService} from '../services/holiday.service';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-add-alarm-definition',
  templateUrl: './add-alarm-definition.component.html',
  styleUrls: ['./add-alarm-definition.component.css']
})
export class AddAlarmDefinitionComponent implements OnInit {
  form: FormGroup;
  objects$: Observable<SelectView[]>;
  sensors$: Observable<SelectView[]>;
  holidays$: Observable<SelectView[]>;
  workTypes: any[];
  signs = ['<', '<=', '=', '>', '>='];
  subscriptions: Subscription[] = [];
  alarmsLoaded = false;
  success = false;
  failure = false;
  holidayAlarms: AlarmDefinition[] = [];
  dailyAlarms: AlarmDefinition[] = [];
  workTypeAlarms: AlarmDefinition[] = [];
  reloadWithSensorId$: Subject<number> = new Subject<number>();

  constructor(private facilityObjectService: FacilityObjectService,
              private fb: FormBuilder,
              private sensorService: SensorService,
              private holidayService: HolidayService,
              private alarmDefinitionService: AlarmDefinitionService,
              public authService: AuthService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.objects$ = this.facilityObjectService.findAllSelectViews().pipe(
      shareReplay(1)
    );
    this.form = this.fb.group({
      id: [null],
      objectId: [null, Validators.required],
      sensorId: [null, Validators.required],
      sign: [null],
      referenceValue: [null],
      holidayId: [null],
      workTypeId: [null],
      day: [null],
      hour: [null],
    });

    this.workTypes = [
      {id: 1, name: this.getWorkTypeName(1)},
      {id: 2, name: this.getWorkTypeName(2)},
      {id: 3, name: this.getWorkTypeName(3)},
      {id: 4, name: this.getWorkTypeName(4)},
      {id: 5, name: this.getWorkTypeName(5)},
    ]

    const sub = this.form.get('objectId').valueChanges.pipe(
      tap(_ => this.form.get('sensorId').patchValue(null)),
      filter(id => !!id),
      distinctUntilChanged()
    ).subscribe(objectId => {
      this.sensors$ = this.sensorService.findSelectViewsForObject(objectId).pipe(
        shareReplay(1)
      );
    });

    const sub2 =
      merge(
        this.form.get('sensorId').valueChanges.pipe(
        distinctUntilChanged(),
        filter(id => !!id)),
        this.reloadWithSensorId$
      ).pipe(
        tap(sensorId => { this.alarmsLoaded = false; }),
        flatMap((sensorId: number) => this.alarmDefinitionService.findForSensor(sensorId)),
      ).subscribe((alarmDefinitions: AlarmDefinition[]) => {
        this.alarmsLoaded = false;
        this.success = false;
        this.failure = false;
        this.dailyAlarms = [];
        this.holidayAlarms = [];
        this.workTypeAlarms = [];
        this.clearForm();

      alarmDefinitions.map(definition => {
        if (definition.day > 0 && definition.hour >= 0) {
          this.dailyAlarms.push(definition);
        }
        if (definition.workType) {
          this.workTypeAlarms.push(definition);
        }
        if (definition.holiday) {
          this.holidayAlarms.push(definition);
        }
      });
      this.dailyAlarms.sort((a, b) => a.day === b.day ? b.hour - a.hour : b.day - a.day);
      this.holidayAlarms.sort((a, b) => a.holiday.date > b.holiday.date ? -1 : 1);
      this.workTypeAlarms.sort((a, b) => a.workType.id - b.workType.id);
      this.alarmsLoaded = true;
    });

    this.holidays$ = this.holidayService.findSelectViewsYearForward();

    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.has('objectId') && paramMap.has('sensorId')) {
        this.form.get('objectId').setValue(+paramMap.get('objectId'));
        this.form.get('sensorId').setValue(+paramMap.get('sensorId'));
      }
    });

    this.subscriptions.push(sub, sub2);
  }

  onSubmit() {
    this.success = false;
    this.failure = false;
    const data = this.form.value;
    if (data.sign && data.referenceValue && (
      data.holidayId ||
      (data.day > 0 && data.hour >= 0) ||
      data.workTypeId)) {
      this.alarmDefinitionService.save(data).subscribe(
        (result) => {this.success = true; this.reloadWithSensorId$.next(this.form.get('sensorId').value);},
        (error) => {console.log(error)}
      );
    }
    else {
      this.failure = true;
    }
  }

  getWorkTypeName(id: number) {
    return [
      'Otwarte',
      'Zamknięte',
      'Organizacyjna',
      'Organizacyjna przed otwarciem',
      'Organizacyjna po zamknięciu'
    ][id - 1];
  }

  clearForm() {
    this.form.patchValue({
      id: null,
      holidayId: null,
      workTypeId: null,
      day: null,
      hour: null,
      sign: null,
      referenceValue: null
    });
  }

  editWorkType(workTypeId: number) {
    this.alarmDefinitionService.findById(workTypeId).subscribe(
      (workTypeAlarm: AlarmDefinition)  => {
        this.form.patchValue({
          id: workTypeAlarm.id,
          holidayId: null,
          workTypeId: workTypeAlarm.workType.id,
          day: null,
          hour: null,
          sign: workTypeAlarm.sign,
          referenceValue: workTypeAlarm.referenceValue
        });
      }, error => {
        console.log('ERROR');
        console.log(error);
      }
    );
  }

}
