import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {NewObjectComponent} from './new-object/new-object.component';
import {DevicesComponent} from './devices/devices.component';
import {ListNotificationsComponent} from './list-notifications/list-notifications.component';
import {AddNotificationComponent} from './add-notification/add-notification.component';
import {ShowNotificationComponent} from './show-notification/show-notification.component';
import {AddWorkPeriodComponent} from './add-work-period/add-work-period.component';
import {AddAlarmDefinitionComponent} from './add-alarm-definition/add-alarm-definition.component';
import {AddTangensfiDefinitionComponent} from './add-tangensfi-definition/add-tangensfi-definition.component';

const appRoutes: Routes = [
  {
    path: '', canActivate: [AuthGuard], children: [
      {path: '', component: NewObjectComponent},
      {path: 'devices', component: DevicesComponent},
      {path: 'devices/:id', component: DevicesComponent},
      {path: 'list/notifications', component: ListNotificationsComponent},
      {path: 'add/notification', component: AddNotificationComponent},
      {path: 'edit/notification/:id', component: AddNotificationComponent},
      {path: 'notification/:id', component: ShowNotificationComponent},
      {path: 'add/work/period', component: AddWorkPeriodComponent},
      {path: 'work/period/:objectId/:sensorId', component: AddWorkPeriodComponent},
      {path: 'alarm/definitions', component: AddAlarmDefinitionComponent},
      {path: 'power/alarm/definitions', component: AddTangensfiDefinitionComponent}
    ]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false}
    )
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule {
}
