import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {User} from '../models/User';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigurationService} from '../services/configuration.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Client} from '../models/Client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigurationService,
              private cookieService: CookieService,
              private router: Router) {

    this.apiUrl = this.config.get('apiUrl');
  }

  private _user: User = null;

  get user(): User {
    return this._user;
  }

  login(username: string, password: string): Observable<any> {
    const body = new URLSearchParams();
    body.append('grant_type', 'password');
    body.append('username', username);
    body.append('password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    });

    const authenticationRequest = this.http.post(this.apiUrl + '/oauth/token', body.toString(), {headers: headers});

    return authenticationRequest.pipe(switchMap(tokenData => {
        this.cookieService.set(
          'access_token',
          tokenData['access_token'],
        );
        this.cookieService.set(
          'refresh_token',
          tokenData['refresh_token'],
        );
        return this.retrieveUser();
      }),
    );
  }

  retrieveUser(): Observable<Object> {
    return this.http.get(this.apiUrl + '/user').pipe(
      map(result => {
        this._user = <User>result;
        console.log(this._user);
        return this._user;
      })
    );
  }

  getUsers(): Observable<Object[]> {
    return this.http.get(this.apiUrl + '/users').pipe(
      map((resultArray: Object[]) => {
        const users: User[] = [];
        for (const user of resultArray) {
          users.push(<User>user);
        }
        return users;
      }));
  }

  addUser(user: User): Observable<Object> {
    return this.http.post(this.apiUrl + '/add/user', user);
  }

  updateUser(user: User): Observable<Object> {
    return this.http.post(this.apiUrl + '/update/user', user);
  }
  removeUser(username: string): Observable<Object> {
    return this.http.delete(this.apiUrl + '/remove/user/' + username);
  }

  getUser(username: string): Observable<Object> {
    return this.http.get(this.apiUrl + '/user/' + username).pipe(
      map(( result: Object ) => {
        const user: User = <User>result;
        return user;
      })
    );
  }

  getClients(): Observable<Client[]> {
    return this.http.get(this.apiUrl + '/clients').pipe(
      map((resultArray: Object[]) => {
        const clients: Client[] = [];

        for (const client of resultArray) {
          clients.push(<Client>client);
        }

        return clients;
      })
    );
  }

  logout(): void {
    this._user = null;
    this.cookieService.deleteAll();
  }

  refreshTokenRequest(): Observable<any> {
    const body = new URLSearchParams();
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', this.cookieService.get('refresh_token'));

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    });
    return this.http.post(this.apiUrl + '/oauth/token', body.toString(), {headers: headers});
  }
}
