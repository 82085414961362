import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {UtilityService} from '../../services/utility.service';
import {Client} from '../../models/Client';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {User} from '../../models/User';
import {ToastrService } from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  userForm: FormGroup;
  clients: Client[];
  editUser: User;
  c = console.log;

  constructor(private fb: FormBuilder, private utilityService: UtilityService, private authService: AuthService,
              private router: Router, private route: ActivatedRoute, private toastr: ToastrService) {
  }

  ngOnInit() {
     this.userForm = this.fb.group({
       username: ['' , [Validators.required, Validators.minLength(3)]],
       password: ['', [Validators.minLength(3)]],
       fullName: ['', [Validators.required, Validators.minLength(3)]],
       roles: ['', []],
       keyword: ['', Validators.required],
       clientId: ['', [Validators.required]]
     });
     this.loadClients();
     this.loadUser();
   }

  loadClients() {
    this.authService.getClients().subscribe(
      (clients: Client[]) => {
        this.clients = clients;
      }
    );
  }

  loadUser() {
   this.authService.getUser(this.route.snapshot.paramMap.get('id')).subscribe(
     (user: User) => {
       this.editUser = user;
       this.userForm.setValue({
         username: this.editUser.username,
         password: '',
         fullName: this.editUser.fullName,
         roles: this.editUser.roles,
         keyword: this.editUser.keyword,
         clientId: this.editUser.clientId
       });
       // FIXME:Przerobić selecta na stronei html - obecnie są stałe warości
       this.userForm.controls.roles.setValue(this.editUser.roles);
     }
   );
  }

  onSubmit() {
    if (!this.userForm.valid) {
      this.utilityService.touchAllFormControls(this.userForm);
    } else {
      const user: User = <User>this.userForm.value;
      this.authService.updateUser(user).subscribe(
        res => {
          this.toastr.success(res.toString(), 'Edycja użytkownika');
        },
        (error: HttpErrorResponse) => {
          this.toastr.error(error.error, 'Edycja użytkownika');
        }
      );
    }
  }

}
