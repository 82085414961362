import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../services/notification.service';
import {SelectView} from '../models/SelectView';
import {Notification} from '../models/Notification';
import {Enumerations} from '../models/enums';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '../services/utility.service';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.css']
})
/**
 * Komponent do dodawania powiadomien w systemie.
 */
export class AddNotificationComponent implements OnInit {

  notificationForm: FormGroup;
  objects: SelectView[] = [];
  statuses = Enumerations.statuses;
  categories = Enumerations.categories;
  ready = false;
  isEdit = false;


  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private util: UtilityService,
              public notificationService: NotificationService) { }

  ngOnInit() {
    this.notificationForm = this.fb.group({
      author: [null, Validators.required],
      polishDescription: [null, Validators.required],
      englishDescription: [null],
      status: [1, Validators.required],
      category: [1, Validators.required],
      objectId: [null, Validators.required],
    });

    this.notificationService.findAllFacilityObjects().subscribe((res: SelectView[]) => {
      this.objects = res;
    });

    if (this.activatedRoute.snapshot.paramMap.has('id')) {
      this.notificationService.getNotification(+this.activatedRoute.snapshot.paramMap.get('id'))
        .subscribe(notification => {
          this.notificationForm.patchValue(notification);
          this.isEdit = true;
          this.ready = true;
        });
    } else {
      this.ready = true;
    }
  }

  onSubmit() {
    console.log(this.notificationForm);
    const notification: Notification = this.notificationForm.value;
    if (!this.notificationForm.invalid) {
      if (!this.isEdit) {
        this.notificationService.addNotification(notification).subscribe(res => {
          this.router.navigate(['/list/notifications']);
        });
      } else {
        this.notificationService.updateNotification(+this.activatedRoute.snapshot.paramMap.get('id'), notification).subscribe(res => {
          this.router.navigate(['/list/notifications']);
        });
      }
    }
    else {
      this.util.touchAllFormControls(this.notificationForm);
    }
  }
}
