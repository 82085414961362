import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req.url.indexOf('/oauth/token') === -1 &&
    //   req.url.indexOf('assets/config/app-config.json') === -1) {
    //   return next.handle(req.clone({
    //     headers: req.headers.append('Content-Type', 'application/json')
    //   }));
    // }
    // return next.handle(req);
    if (req.url.split('/')[1] === 'assets') {
      return next.handle(req);
    } else if (req.url.indexOf('oauth/token') === -1) {
      return next.handle(req.clone({
        url: '/api' + req.url,
        headers: req.headers.append('Content-Type', 'application/json')
      }));
    }

    return next.handle(req.clone({
      url: '/api' + req.url
    }));
  }
}
