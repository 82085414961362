import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from './configuration.service';
import {map} from 'rxjs/operators';
import {SelectView} from '../models/SelectView';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {

  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  findSelectViewsYearForward() {
    return this.http.get(this.apiUrl + '/holiday/year/forward').pipe(
      map(( holiday: any[] ) => holiday.map(h => new SelectView(h.id, `${moment(h.date).format('YYYY-MM-DD')} - ${h.name} (${h.country})`)))
    );
  }
}
