import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DeviceService} from '../services/device.service';
import {DataLogger} from '../models/DataLogger';
import {ActivatedRoute, Router} from '@angular/router';
import {Device} from '../models/Device';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {

  deviceForm: FormGroup;
  devices: DataLogger[];
  deviceToShow: Device = null;
  selectedLabel = '';

  constructor(private fb: FormBuilder, private deviceService: DeviceService, private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    console.log(this.selectedLabel);
    this.deviceForm = this.fb.group({
      configurationId: [null, []]
    });

    this.deviceService.getDevicesWithObject().subscribe(
      (devices: DataLogger[]) => {
        this.devices = devices;

        this.selectedLabel = this.activatedRoute.snapshot.paramMap.get('id');

        if (this.selectedLabel) {
          const dl: DataLogger = this.devices.find(d => d.identifier === this.selectedLabel);
          this.deviceForm.get('configurationId').patchValue(dl.id);
          this.getDeviceInformation(dl.id.toString());
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  updateUrl(event: any) {
    const confId = this.deviceForm.controls.configurationId.value;
    console.log(confId);
    const dataLogger = this.devices.find((val) => val.id.toString() === confId.toString());
    console.log(dataLogger);

    this.router.navigate(['devices/' + dataLogger.identifier]);

    this.getDeviceInformation(confId);
  }

  getDeviceInformation(deviceId: string) {
    this.deviceService.getDevice(deviceId).subscribe(
      (device: Device) => {
        console.log(device);
        this.deviceToShow = device;
      },
      error => console.log(error)
    );
  }

  /**
   * Walidacja, czy w opisie urządzenia jest prawidłowy numer OBIS
   * @param desc
   */
  isObis(desc: string) {
    return /[0-9]*\.[0-9]*\.[0-9]*/.test(desc);
  }
}
