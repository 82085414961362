import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from './configuration.service';
import {map} from 'rxjs/operators';
import {SelectView} from '../models/SelectView';
import {Notification} from '../models/Notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  public getAllNotifications(page = 0, filter) {
    return this.http.post(this.apiUrl + '/notifications/' + page, filter).pipe(
      map((notifications: Object[]) => {
        return notifications.map(n => <Notification>n);
      })
    );
  }
  public getNotification(id: number) {
    return this.http.get(this.apiUrl + '/notification/' + id.toString()).pipe(
      map((notification: Object) => {
        return <Notification>notification;
      })
    );
  }

  public addNotification(notification: Notification) {
    return this.http.post(this.apiUrl + '/add/notification', JSON.stringify(notification)).pipe(
      map((notif: Object) => {
        return <Notification>notif;
      })
    );
  }

  public updateNotification(id: number, notification: Notification) {
    return this.http.put(this.apiUrl + '/update/notification/' + id.toString(), notification).pipe(
      map((notif: Object) => {
        return <Notification>notif;
      })
    );
  }

  public findAllFacilityObjects() {
    return this.http.get(this.apiUrl + '/get/objects').pipe(
      map((result: any[]) => {
        return result.map(object => new SelectView(object.id,
          `${object.storeNumber} - ${object.mall} - ${object.city}, ${object.country}`
        ));
      })
    );
  }
}
