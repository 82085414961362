import {APP_INITIALIZER, NgModule} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from './auth/auth.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ConfigurationService} from './services/configuration.service';
import {CookieService} from 'ngx-cookie-service';
import {NavbarComponent} from './navbar/navbar.component';
import {NewObjectComponent} from './new-object/new-object.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PatternOnlyDirective} from './directives/pattern-only.directive';
import {DeviceService} from './services/device.service';
import {HeaderInterceptor} from './header.interceptor';
import {ShowDeviceComponent} from './show-device/show-device.component';
import {ControlsModule} from './controls/controls.module';
import {DevicesComponent} from './devices/devices.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { ListNotificationsComponent } from './list-notifications/list-notifications.component';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { ShowNotificationComponent } from './show-notification/show-notification.component';
import { AddWorkPeriodComponent } from './add-work-period/add-work-period.component';
import { AddAlarmDefinitionComponent } from './add-alarm-definition/add-alarm-definition.component';
import { AddTangensfiDefinitionComponent } from './add-tangensfi-definition/add-tangensfi-definition.component';
import { ToastrModule } from 'ngx-toastr';

export function initializeApp(config: ConfigurationService) {
  return () => config.load();
}

@NgModule({
  declarations: [
    PatternOnlyDirective,
    AppComponent,
    NavbarComponent,
    NewObjectComponent,
    ShowDeviceComponent,
    DevicesComponent,
    ListNotificationsComponent,
    AddNotificationComponent,
    ShowNotificationComponent,
    AddWorkPeriodComponent,
    AddAlarmDefinitionComponent,
    AddTangensfiDefinitionComponent,
  ],
  imports: [
    NgSelectModule,
    HttpClientModule,
    BrowserModule,
    AuthModule,
    ControlsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    ConfigurationService,
    CookieService,
    DeviceService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigurationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
