import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UtilityService} from '../../services/utility.service';
import {AuthService} from '../auth.service';
import {User} from '../../models/User';
import {Client} from '../../models/Client';
import {ToastrService } from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  userForm: FormGroup;
  clients: Client[];
  c = console.log;

  constructor(private fb: FormBuilder, private utilityService: UtilityService, private authService: AuthService, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      roles: ['', []],
      keyword: ['', Validators.required],
      clientId: ['', [Validators.required]]
    });

    this.loadClients();
  }

  get f() {
    return this.userForm.controls;
  }

  loadClients() {
    this.authService.getClients().subscribe(
      (clients: Client[]) => {
        this.clients = clients;
      }
    );
  }

  onSubmit() {
    if (!this.userForm.valid) {
      this.utilityService.touchAllFormControls(this.userForm);
    } else {
      const user: User = <User>this.userForm.value;
      user.roles = this.f.roles.value.length > 0 ? [this.f.roles.value, 'USER'] : ['USER'];

      this.authService.addUser(user).subscribe(
        res => {
          this.toastr.success(res.toString(), 'Dodawnie użytkownika');
        },
        (error: HttpErrorResponse) => {
          this.toastr.error(error.error, 'Dodawanie użytkownika');
        }
      );
    }
  }

}
