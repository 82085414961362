import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) {
  }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    const cookieService = this.injector.get(CookieService);

    if (cookieService.check('access_token') &&
      req.url.indexOf('/oauth/token') === -1 &&
      req.url.indexOf('assets/config/app-config.json') === -1) {
      return req.clone({
        headers: req.headers.append('Authorization', 'Bearer ' + cookieService.get('access_token'))
      });
    }
    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            return this.handleUnauthorized(req, next, error);
          }
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  handleUnauthorized(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    const cookieService = this.injector.get(CookieService);
    const authService = this.injector.get(AuthService);
    const router = this.injector.get(Router);

    if (!this.isRefreshingToken) {
      console.log('Odświeżam token...');
      this.isRefreshingToken = true;

      return authService.refreshTokenRequest().pipe(
        switchMap(tokenData => {
          if (tokenData['access_token']) {
            cookieService.set('access_token', tokenData['access_token']);
            cookieService.set('refresh_token', tokenData['refresh_token']);
            this.tokenSubject.next(tokenData['access_token']);
            return next.handle(this.addToken(req));
          }
          return throwError(error);
        }),
        catchError(e => {
          console.log('Nie udało się odświeżyć tokena');
          authService.logout();
          router.navigate(['/login'], {queryParams: {returnUrl: req.url}});
          return throwError(e);
        }),
        finalize(() => {
          console.log('Nie refreszuję już');
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req));
        })
      );
    }
  }

}
