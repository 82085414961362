import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  redirectUrl: string;
  loginError = false;
  connectionError = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) {

    if (this.authService.user) {
      this.router.navigate(['/']);
    }

  }

  get form() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || '/';
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.login(this.form.username.value, this.form.password.value)
      .subscribe(
        value => {
          this.router.navigate([this.redirectUrl]);
          this.loginError = false;
          this.connectionError = false;
        },
        error => {
          if (error.status === 400) {
            this.connectionError = false;
            this.loginError = true;
          }
          if (error.status === 0) {
            this.loginError = false;
            this.connectionError = true;
          }
        }
      );
  }

}
