import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {NotificationService} from '../services/notification.service';
import {Notification} from '../models/Notification';
import {Router} from '@angular/router';
import {UtilityService} from '../services/utility.service';
import {Enumerations} from '../models/enums';
import {SelectView} from '../models/SelectView';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.css']
})
export class ListNotificationsComponent implements OnInit {

  notifications: Notification[] = [];
  loadMore = true;
  page = 0;

  appliedFilters = {
    category: null,
    status: null,
    objectId: null
  };

  filterForm: FormGroup;

  categories = Enumerations.categories;
  statuses = Enumerations.statuses;
  objects = [];

  constructor(private authService: AuthService,
              private notificationService: NotificationService,
              public util: UtilityService,
              private fb: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.reloadNotifications(0);
    this.notificationService.findAllFacilityObjects().subscribe((res: SelectView[]) => {
      this.objects = res;
    });

    this.filterForm = this.fb.group({
      status: [null],
      category: [null],
      objectId: [null],
    });

  }

  showNotification(id: number) {
    this.router.navigate(['/notification/' + id]);
  }

  reloadNotifications(page: number) {
    this.page = page;
    this.notificationService.getAllNotifications(page, this.appliedFilters).subscribe((value: Notification[]) => {
      if (value.length < 30) {
        this.loadMore = false;
      } else {
        this.loadMore = true;
      }
      this.notifications = this.notifications.concat(value);
    });
  }

  filterNotifications() {
    this.appliedFilters = this.filterForm.value;
    this.notifications.splice(0);
    this.reloadNotifications(0);
  }
}
