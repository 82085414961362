import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AddUserComponent} from './add-user/add-user.component';
import {UsersComponent} from './users/users.component';
import {AdminGuard} from './admin.guard';
import {EditUserComponent} from './edit-user/edit-user.component';

const authRoutes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'admin', canActivate: [AdminGuard], children: [
      {path: 'add/user', component: AddUserComponent},
      {path: 'users', component: UsersComponent},
      {path: 'edit/user/:id', component: EditUserComponent}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [
    RouterModule
  ],
})
export class AuthRoutingModule {
}
