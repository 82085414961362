export class Enumerations {
  static statuses = [
    {id: 1, name: 'Serwis'},
    {id: 2, name: 'Zamknięty'},
    {id: 3, name: 'Informacja'},
    {id: 4, name: 'Historia'},
  ];

  static categories = [
    {id: 1, name: 'Serwis'},
    {id: 2, name: 'Informacja'},
  ];
}
