import {Injectable} from '@angular/core';
import {Configuration} from '../models/Configuration';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private config: Configuration;

  constructor(private http: HttpClient) {
  }

  load() {
    return new Promise((resolve, reject) => {
      this.http.get('/assets/config/app-config.json').subscribe(
        (data) => {
          this.config = <Configuration>data;
          resolve(true);
        },
        (error) => {
          console.error(error);
          reject('Nie znaleziono konfiguracji');
        });
    });
  }

  get(key: any) {
    return this.config[key];
  }
}
