import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlarmDefinitionService {
  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  findForSensor(sensorId: number) {
    return this.http.get(this.apiUrl + '/alarm/definitions/for/sensor/' + sensorId);
  }

  findById(alarmId: number) {
    return this.http.get(this.apiUrl + '/alarm/definitions/id/' + alarmId);
  }

  save(data: any) {
    return this.http.post(this.apiUrl + '/save/alarm/definition', JSON.stringify(data));
  }
}
