import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from './configuration.service';
import {map} from 'rxjs/operators';
import {DataLogger} from '../models/DataLogger';
import {NewObject} from '../models/NewObject';
import {Device} from '../models/Device';
import {MeterLocation} from '../models/MeterLocation';
import {DeviceMeters} from '../models/DeviceMeters';
import {MeterModel} from '../models/MeterModel';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  public getDevicesWithoutObject() {
    return this.http.get(this.apiUrl + '/devices/without/object').pipe(
      map((devices: Object[]) => {
        console.log(devices);
        return devices.map(device => <DeviceMeters>device);
      })
    );
  }

  public getDevicesWithObject() {
    return this.http.get(this.apiUrl + '/devices/with/object').pipe(
      map((devices: Object[]) => {
        console.log(devices);
        return devices.map(device => <DataLogger>device);
      })
    );
  }

  public addNewObject(data: NewObject) {
    return this.http.post(this.apiUrl + '/add/device', JSON.stringify(data));
  }

  public getDevice(identifier: String) {
    return this.http.get(this.apiUrl + '/device/' + identifier).pipe(
      map((device: Object) => {
        console.log(device);
        return <Device>device;
      })
    );
  }

  public getLocations() {
    return this.http.get(this.apiUrl + '/locations').pipe(
      map((locations: Object[]) => {
        const result: MeterLocation[] = [];
        for (const loc of locations) {
          result.push(<MeterLocation>loc);
        }
        return result;
      })
    );
  }

  public getMeterModels() {
    return this.http.get(this.apiUrl + '/meter/models').pipe(
      map((models: Object[]) => {
        return models.map(x => <MeterModel>x);
      })
    );
  }
}
