import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SelectView} from '../models/SelectView';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, flatMap, map, shareReplay, tap} from 'rxjs/operators';
import {FacilityObjectService} from '../services/facility-object.service';
import {SensorService} from '../services/sensor.service';
import {WorkPeriod} from '../models/WorkPeriod';
import {WorkPeriodService} from '../services/work-period.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-add-work-period',
  templateUrl: './add-work-period.component.html',
  styleUrls: ['./add-work-period.component.css']
})
export class AddWorkPeriodComponent implements OnInit, OnDestroy {
  form: FormGroup;
  objects$: Observable<SelectView[]>;
  sensors$: Observable<any[]>;
  subscriptions: Subscription[] = [];
  submitted = false;
  success = false;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private sensorService: SensorService,
              private facilityObjectService: FacilityObjectService,
              private router: Router,
              private workPeriodService: WorkPeriodService) { }

  ngOnInit() {
    this.objects$ = this.facilityObjectService.findAllSelectViews().pipe(
      shareReplay(1)
    );

    this.form = this.fb.group({
      objectId: [null, Validators.required],
      sensorId: [null, Validators.required],
      workPeriods: this.fb.array([]),
    });

    const sub = this.form.get('objectId').valueChanges.pipe(
      tap(_ => this.form.get('sensorId').patchValue(null)),
      filter(id => !!id),
      distinctUntilChanged()
    ).subscribe(objectId => {
      this.sensors$ = this.sensorService.findSelectViewsForObject(objectId).pipe(
        shareReplay(1)
      );
    });

    const sub2 = this.form.get('sensorId').valueChanges.pipe(
      tap(_ => {
        for (let i = 0; i < 7; i++) {
          (<FormArray>this.form.get('workPeriods')).removeAt(0);
        }
      }),
      filter(id => !!id),
      distinctUntilChanged(),
      flatMap(sensorId => this.workPeriodService.findWorkPeriodsForSensor(sensorId))
    ).subscribe((workPeriods: WorkPeriod[]) => {
      for (let i = 1; i < 8; i++) {
        (<FormArray>this.form.get('workPeriods')).push(this.generateDayForm(i));
      }

      if (workPeriods.length > 0) {
        for (const periods of (<FormArray>this.form.get('workPeriods')).controls) {
          const dayPeriods = workPeriods.filter(wp => wp.day === periods.get('day').value);
          for (const hour of (<FormArray>periods.get('hours')).controls) {
            hour.patchValue(dayPeriods.filter(dp => dp.hour === hour.get('hour').value)[0]);
          }
        }
      }
    });

    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.has('objectId') && paramMap.has('sensorId')) {
        this.form.get('objectId').setValue(+paramMap.get('objectId'));
        this.form.get('sensorId').setValue(+paramMap.get('sensorId'));
      }
    });

    this.subscriptions.push(sub, sub2);
  }

  private generateDayForm(dayNumber: number) {
    const dayForm = this.fb.group({
      day: [dayNumber, Validators.required],
      hours: this.fb.array([])
    });

    for (let i = 0; i < 24; i++) {
      (<FormArray>dayForm.get('hours')).push(this.generateHourForm(i));
    }

    return dayForm;
  }

  private generateHourForm(hourNumber: number) {
    return this.fb.group({
      hour: [hourNumber, Validators.required],
      workTypeId: [1, Validators.required]
    });
  }

  toFormArray(workPeriods: AbstractControl) {
    return workPeriods as FormArray;
  }

  weekdayString(numberOfDay: number) {
    return [
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
      'Niedziela'
    ][numberOfDay - 1];
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.workPeriodService.saveWorkPeriods(this.form.value).subscribe(
        (result) => {
          this.submitted = false;
          this.success = true;
          this.router.navigate(['/work/period/', this.form.get('objectId').value, this.form.get('sensorId').value])
        },
        (error) => {
          console.log(error);
          this.submitted = false;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  copyToAll(source: number) {
    const data = this.form.value.workPeriods.filter(wp => wp.day === source)[0].hours;
    for (let i = 0; i < 7; i++) {
     (<FormArray>this.form.get('workPeriods')).controls[i].get('hours').patchValue(data);
    }
  }
}
