import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';
import {PowerAlarmDefinition} from '../models/PowerAlarmDefinition';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PowerAlarmService {
  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  findAllForObject(objectId): Observable<PowerAlarmDefinition[]> {
    return this.http.get<PowerAlarmDefinition[]>(this.apiUrl + '/power/alarms/for/object/' + objectId);
  }

  save(value: any) {
    return this.http.post(this.apiUrl + '/save/power/alarm', JSON.stringify(value));
  }

  delete(alarmId: string) {
    return this.http.delete(this.apiUrl + '/delete/power/alarm/' + alarmId);
  }
}
