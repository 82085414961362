import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Enumerations} from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() {
  }

  touchAllFormControls(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.touchAllFormControls(control);
      }
    });
  }

  getStatusName(status: number) {
    return Enumerations.statuses.filter(s => s.id === status)[0].name;
  }

  getCategoryName(category: number) {
    return Enumerations.categories.filter(c => c.id === category)[0].name;
  }

  cutText(text: string) {
    let newText = text.substring(0, 50);
    if (text.length > 50) {
      newText += '...';
    }
    return newText;
  }
}

