import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkPeriodService {
  apiUrl: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.apiUrl = this.configService.get('apiUrl');
  }

  public saveWorkPeriods(data: any) {
    return this.http.post(this.apiUrl + '/save/work/periods', JSON.stringify(data));
  }

  public findWorkPeriodsForSensor(sensorId: number) {
    return this.http.get(this.apiUrl + '/work/periods/for/sensor/' + sensorId);
  }
}
